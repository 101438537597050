<template lang="pug">
.vc-sponsor-card
  .inner
    .cover-wrapper
      .cover(
        :style="`background-image: url('${sponsor.member_info.bg_image.url}')`"
      )
        .overlay
        .sponsor-info
          k-sponsor-info.info-box.-is-box(:sponsor-info="sponsorInfo")
          .options
            a.button.is-fullwidth(:href="sponsor.storeFrontUrl()")
              span {{ actionLocaleText('check_all') }}
    .product-list-wrapper
      product-list(:sponsor="sponsor")
</template>

<script setup lang="ts">
import { computed } from 'vue'
import KSponsorInfo from '../common/k-sponsor-info.vue'
import ProductList from './product-list.vue'

interface Sponsor {
  member_info: {
    avatar: {
      url: String
    }
    level_image: {
      url: String
    }
    bg_image: {
      url: String
    }
    name: String
  }
}

// props
const props = defineProps<{
  sponsor: Sponsor
}>()

// computed
/**
 * @returns {computedRef<Object>}
 */
const sponsorInfo = computed<Object>(() => {
  return {
    avatar: {
      url: props.sponsor.member_info.avatar.url
    },
    level_image: {
      url: props.sponsor.member_info.level_image.url
    },
    name: props.sponsor.member_info.name
  }
})
</script>
