<template lang="pug">
.vc-info-link-info-box(:class="useTypesClasses")
  .inner
    a.image-wrapper(
      :href="infoLink.url"
      target="_blank"
      ref="noopenner noreferrer"
    )
      img.image(v-lazy="infoLink.cover.url")

      k-sponsor-info.sponsor-bar.-is-bar(
        v-if="isHasSponsor"
        :sponsor-info="infoLink.sponsor"
        :slogan="infoLink.sponsor_slogan"
      )

    .info-wrapper
      .info-title
        | {{ infoLink.title }}
      .info-sub-title
        | {{ infoLink.sub_title || '&nbsp;' }}
</template>

<script lang="ts" setup>
import { computed } from 'vue'
import KSponsorInfo from '../common/k-sponsor-info.vue'

const props = defineProps<{
  infoLink: Object
}>()

// computed
/**
 * @returns {computedRef<Object>}
 */
const useTypesClasses = computed<Object>(() => {
  return {
    '-is-kolnet-box': [
      'main_campaign',
      'campaign_theme',
      'life_style'
    ].includes(props.infoLink.use_type),
    '-is-half': ['campaign_theme', 'life_style'].includes(
      props.infoLink.use_type
    ),
    '-has-sponsor': isHasSponsor.value,
    '-top-info':
      ['campaign_theme', 'life_style'].includes(props.infoLink.use_type) &&
      isHasSponsor.value,
    '-bottom-info':
      ['campaign_theme', 'life_style'].includes(props.infoLink.use_type) &&
      !isHasSponsor.value
  }
})
/**
 * @returns {computedRef<Boolean>}
 */
const isHasSponsor = computed<Boolean>(() => {
  return !!props.infoLink.sponsor_id
})
</script>
