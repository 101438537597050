<template lang="pug">
.promter-register-form-wrapper
  .section.promter-register-form
    .title
      | 基本資料
    b-field(
      :label="'帳號'"
      :type="errors.errorClassAt('email')"
      :message="errors.get('email')"
      custom-class="required"
      horizontal
    )
      b-input(
        type="text"
        placeholder="輸入 Email"
        v-model="form.email"
        data-behavior="promoter-email"
        name="promoter_member[email]"
        @input="errors.clear('email')"
      )

    b-field(
      :label="`${attributeLocaleText('promoter_member', 'name')}(將顯示於賣場)`"
      :type="errors.errorClassAt('name')"
      :message="errors.get('name')"
      custom-class="required"
      horizontal
    )
      b-input(
        type="text"
        placeholder="e.g. Jone Doe"
        v-model="form.name"
        data-behavior="promoter-name"
        @input="errors.clear('name')"
      )

    b-field(
      :label="`${attributeLocaleText('promoter', 'real_name')} (不會顯示於賣場)`"
      :type="errors.errorClassAt('real_name')"
      :message="errors.get('real_name')"
      custom-class="required"
      horizontal
    )
      b-input(
        type="text"
        placeholder="e.g. Jone Doe"
        v-model="form.real_name"
        data-behavior="promoter-real_name"
        @input="errors.clear('real_name')"
      )
    b-field(
      :label="`${attributeLocaleText('promoter', 'birthday')} (不會顯示於賣場)`"
      :type="errors.errorClassAt('birthday')"
      :message="errors.get('birthday')"
      custom-class="required"
      horizontal
    )
      b-datepicker(
        v-model="birthday"
        placeholder="e.g. 2023/01/01"
        data-behavior="promoter-birthday"
        @input="errors.clear('birthday')"
      )
    b-field(
      :label="attributeLocaleText('promoter', 'password')"
      :type="errors.errorClassAt('password')"
      :message="errors.get('password')"
      custom-class="required"
      horizontal
    )
      b-input(
        type="password"
        :placeholder="attributeLocaleText('admin', 'password')"
        autocomplete="new-password"
        v-model="form.password"
        data-behavior="promoter-password"
        name="promoter_member[password]"
        @input="errors.clear('password')"
      )

    b-field(
      :label="attributeLocaleText('promoter', 'password_confirmation')"
      :type="errors.errorClassAt('password_confirmation')"
      :message="errors.get('password_confirmation')"
      custom-class="required"
      horizontal
    )
      b-input(
        type="password"
        :placeholder="messageLocaleText('help.please_re_enter_x_for_confirmation', { x: attributeLocaleText('admin', 'password') })"
        v-model="form.password_confirmation"
        data-behavior="promoter-password-confirmation"
        @input="errors.clear('password_confirmation')"
      )

  .section
    media-accounts-selector(
      :media-accounts.sync="form.media_accounts"
      :errors="errors"
    )

  .section
    .title
      | 聯絡資料
    .help
      span 填寫下面完整聯絡資料，可加速 {{ copyLocaleText('site_basics.site_name') }} 團隊與您聯繫，且當有好商品上架時立即通知您。

    b-field(
      :label="attributeLocaleText('promoter', 'contact_phone')"
      :type="errors.errorClassAt('contact_phone')"
      :message="errors.get('contact_phone')"
      horizontal
    )
      b-input(
        type="text"
        placeholder="e.g. 09xx-xxxxxx"
        v-model="form.contact_phone"
        data-behavior="promoter-contact_phone"
        @input="errors.clear('contact_phone')"
      )

    b-field(
      :label="attributeLocaleText('promoter/profile', 'contact_lineid')"
      :type="errors.errorClassAt('contact_lineid')"
      :message="errors.get('contact_lineid')"
      horizontal
    )
      b-input(
        type="text"
        placeholder="e.g. iamwin"
        v-model="form.contact_lineid"
        data-behavior="promoter-contact_lineid"
        @input="errors.clear('contact_lineid')"
      )
</template>

<script>
import MediaAccountsSelector from '../../../../admin/js/components/promoter/media_accounts_selector.vue'
import moment from 'moment'

export default {
  components: {
    MediaAccountsSelector
  },

  props: {
    form: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      isLoading: false,
      birthday: null
    }
  },

  computed: {
    errors() {
      return this.form.model.errors
    }
  },

  watch: {
    birthday: {
      handler(newValue) {
        this.form.birthday = moment(this.birthday).unix()
      }
    }
  },

  async mounted() {
    this.initMediaAccounts()
  },

  methods: {
    initMediaAccounts() {
      if (this.form.media_accounts.length === 0)
        this.form.media_accounts.push({
          media_service_id: 1
        })
    }
  }
}
</script>
