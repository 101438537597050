<template lang="pug">
.vc-sales-event-k-card
  .inner
    .cover-wrapper
      .cover(:style="`background-image: url('${salesEvent.cover.url}')`")
        .overlay
        .sales-info
          h2.name {{ salesEvent.name }}
          .countdown-wrapper(:class="{ 'is-live': salesEvent.isLive() }")
            .counter-inner
              countdown(
                :time="countdownTime"
                :interval="1000"
              )
                template(slot-scope="props")
                  span.counter
                    span.days
                      span.unit(
                        v-for="(day, index) in confirmedDays(props.days)"
                        :key="index"
                      ) {{ day }}
                    span.hours
                      span.unit(
                        v-for="(hour, index) in splitNumber(props.hours)"
                        :key="index"
                      ) {{ hour }}
                    span.minutes
                      span.unit(
                        v-for="(minute, index) in splitNumber(props.minutes)"
                        :key="index"
                      ) {{ minute }}
                    span.seconds
                      span.unit(
                        v-for="(second, index) in splitNumber(props.seconds)"
                        :key="index"
                      ) {{ second }}
    .products-wrapper
      k-sales-event-product-list(:sales-event="salesEvent")
    .options
      a.button.is-default.is-outlined.is-fullwidth(
        :href="`/sales-events/${salesEvent.id}`"
      )
        span {{ copyLocaleText('view_the_best_price') }}
</template>

<script>
import KSalesEventProductList from './k-product-list.vue'
import salesEventMixin from '../mixins/sales_event_mixin.js'

export default {
  components: {
    KSalesEventProductList
  },

  mixins: [salesEventMixin],

  props: {
    salesEvent: {
      type: Object,
      required: true
    }
  },
  // data() {
  //   return {}
  // },
  // computed: {},
  // created() {},
  // mounted() {},
  methods: {
    confirmedDays(days) {
      if (parseInt(days) >= 99) return String(99).split('')

      return String(days).split('')
    },

    splitNumber(number) {
      return String(number).padStart(2, '0').split('')
    }
  }
}
</script>
