<template lang="pug">
.vc-sales-event-k-select-events
  b-loading(
    :active.sync="isLoading"
    :is-full-page="false"
  )

  .list-wrapper
    k-sales-event-card(
      v-for="event in salesEvents"
      :key="event.id"
      :sales-event="event"
    )
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue'
import { useStore } from 'skid-composables'
import KSalesEventCard from './k-card.vue'

// props
const props = defineProps<{
  salesEventIds?: Array<Object>
}>()

const store = useStore()

// computed
/**
 * @returns {computedRef<Boolean>}
 */
const isLoading = computed(() => {
  return store.getters['salesEvents/isLoading']
})
/**
 * @returns {computedRef<Boolean>}
 */
const salesEvents = computed(() => {
  return store.getters['salesEvents/all'].filter((salesEvent) => {
    return props.salesEventIds.includes(salesEvent.id)
  })
})

// mounted
onMounted(async () => {
  await fetchingSelectSalesEvents()
})

// methods
/**
 * @reutrns {void}
 */
const fetchingSelectSalesEvents = () => {
  if (props.salesEventIds.length === 0) return

  store.dispatch('salesEvents/all', {
    search: {
      id_in: props.salesEventIds
    }
  })
}
</script>
