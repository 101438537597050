<template lang="pug">
.vc-sponsor-select-sponsors
  sponsor-card(
    v-for="promoterShare in PromoterShares"
    :key="promoterShare.id"
    :sponsor="promoterShare"
  )
</template>

<script setup lang="ts">
import { computed, onMounted, watch, ref } from 'vue'
import { useStore } from 'skid-composables'
import SponsorCard from './card.vue'

// props
const props = defineProps<{
  promoterShareIds: Array<String>
}>()

// data
const promoterShareIds = ref<Array<String>>([])

const store = useStore()

// computed
/**
 * @returns {computedRef<Array<Object>>}
 */
const PromoterShares = computed(() => {
  return store.getters['promoterShares/all'].filter((promoter_share) => {
    return promoterShareIds.value.includes(promoter_share.id)
  })
})

// mounted
onMounted(async () => {
  await fetchSponsors()
})

// watch
watch(
  () => props.promoterShareIds,
  async (newValue, oldValue) => {
    await fetchPromoterShares()
  }
)

// methods
/**
 * @returns {void}
 */
const fetchSponsors = async () => {
  await fetchPromoterShares()
}
/**
 * @returns {void}
 */
const fetchPromoterShares = async () => {
  if (props.promoterShareIds.length <= 0) return

  const response = await store.dispatch('promoterShares/fetchBriefMemberInfo', {
    forSearch: true,
    search: {
      id_in: props.promoterShareIds
    }
  })
  promoterShareIds.value = response.data.data.map(
    (promoter_share) => promoter_share.id
  )
}
</script>
