<template lang="pug">
.vc-sales-event-k-product-unit
  k-product-unit(:product-info="productInfo")
</template>

<script>
import salesEventPricesMixin from '../mixins/sales_event_prices_mixin.js'
import KProductUnit from '../common/k-product-unit.vue'

export default {
  components: {
    KProductUnit
  },

  mixins: [salesEventPricesMixin],

  props: {
    product: {
      type: Object,
      required: true
    }
  },

  // data() {
  //   return {}
  // },
  computed: {
    _eventPriceCompareTarget() {
      return this.product
    },

    productInfo() {
      return {
        imageUrl: this.product.coverImageThumb(),
        name: this.product.name,
        price: this.toMoney(this.product.original_price).format()
      }
    }
  }
  // created() {},
  // mounted() {},
  // methods: {}
}
</script>
