<template lang="pug">
.vc-sponsor-product-list
  k-product-unit(
    v-for="shareProductShip in shareProductShips"
    :key="shareProductShip.id"
    :product-info="productInfo(shareProductShip)"
  )
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from 'vue'
import { useStore, useMoney } from 'skid-composables'
import KProductUnit from '../common/k-product-unit.vue'

export interface Props {
  sponsor: Object
  productCount?: Number
}

// props
const props = withDefaults(defineProps<Props>(), {
  productCount: () => 3
})

// data
const shareProductShipIds = ref<Array<String>>([])

const store = useStore()
const { toMoney } = useMoney()

// computed
/**
 * @returns {computedRef<Array<Object>>}
 */
const shareProductShips = computed(() => {
  return store.getters['promoterShareProductShips/all'].filter(
    (share_product_ship) => {
      return shareProductShipIds.value.includes(share_product_ship.id)
    }
  )
})

// mounted
onMounted(() => {
  fetchPromoterShareProductShips()
})

// methods
/**
 * @returns {void}
 */
const fetchPromoterShareProductShips = async () => {
  const response = await store.dispatch(
    'promoterShares/fetchBriefShareProductShips',
    {
      model: props.sponsor,
      options: {
        pageSize: 3,
        pageNumber: 1
      }
    }
  )
  shareProductShipIds.value = response.data.data.map(
    (share_product_ship) => share_product_ship.id
  )
}
/**
 * @returns {Object}
 */
const masterEventVariantShip = (shareProductShip) => {
  return store.getters['promoterEventVariantShips/find'](
    shareProductShip.value.event_variant_ship_id
  )
}
/**
 * @returns {Object}
 */
const productInfo = (shareProductShip) => {
  return {
    imageUrl: shareProductShip.product.cover.url,
    name: shareProductShip.product.name,
    price: toMoney(shareProductShip.master_event_variant_ship.price).format()
  }
}
</script>
