<template lang="pug">
.vc-sales-event-k-product-list
  b-loading(
    :active.sync="isLoading"
    :is-full-page="false"
  )

  k-product-unit(
    v-for="product in products"
    :key="product.id"
    :product="product"
    :sales-event="salesEvent"
  )
</template>

<script>
import KProductUnit from './k-product-unit.vue'

export default {
  components: {
    KProductUnit
  },

  // mixins: [],

  props: {
    salesEvent: {
      type: Object,
      required: true
    },

    productCount: {
      type: Number,
      reuqired: false,
      default: () => {
        return 3
      }
    }
  },

  data() {
    return {
      productIds: []
    }
  },

  computed: {
    isLoading() {
      return this.$store.getters['products/isLoading']
    },

    products() {
      return this.$store.getters['products/all'].filter((product) =>
        this.productIds.includes(product.id)
      )
    }
  },

  // created() {},

  mounted() {
    this.fetchingSalesEventProducts()
  },

  methods: {
    fetchingSalesEventProducts() {
      this.$store
        .dispatch(`salesEvents/fetchProducts`, {
          model: this.salesEvent,
          options: {
            pageNumber: 1,
            pageSize: this.productCount,
            sort: 'sales_event_ships.position',
            replace: false
          }
        })
        .then((response) => {
          this.productIds = response.data.data.map((product) => product.id)
        })
    }
  }
}
</script>
